











































































































































































































































import { Vue, Component } from "vue-property-decorator";
import Aside from "./--Aside.vue";
import {
  IncidentRouter,
  AdminRouter,
  ComplainRouter,
} from "@/utils/routePathContsant";
import helpers from "../../utils/helpers";
import { MetaModule } from "@/store/modules";
@Component({
  components: {},
})
export default class Sidenav extends Vue {
  get IncidentRouter() {
    return IncidentRouter;
  }
  get ComplainRouter() {
    return ComplainRouter;
  }
  get AdminRouter() {
    return AdminRouter;
  }
  get isAdmin() {
    const profile = helpers.getUserProfile();
    if (!profile) return false;
    return profile.Role == "admin";
  }
  get isCommandOfficers() {
    const profile = helpers.getUserProfile();
    if (!profile) return false;
    return profile.Role == "command officer";
  }

  get id() {
    const profile = helpers.getUserProfile();
    if (!profile) return false;
    return profile.id;
  }

  get approvedUser() {
    const profile = helpers.getUserProfile();
    if (profile && profile.userDetail && profile.userDetail.profile) {
      return profile.userDetail.profile.approvalStatus == "Approved"
        ? true
        : false;
    }
    return false;
  }

  get routes() {
    return MetaModule.routesListOfUser;
  }

  hasRoles(roles: string[]) {
    return helpers.hasRoles(roles);
  }
}
