













import { Vue, Component } from "vue-property-decorator";
import Navbar from "@/components/UIComponents/Navbar.vue";
import Sidenav from "@/components/UIComponents/Sidenav.vue";
import Msgchat from "@/components/UIComponents/Msgchat.vue";

@Component({
  components: {
    Navbar,
    Sidenav,
    Msgchat,
  },
})
export default class MyComponent extends Vue {
  async created() {
    const previousLayout = localStorage.getItem("previousLayout");
    if (previousLayout && previousLayout !== "Layout") {
      window.location.reload();
    }
    localStorage.setItem("previousLayout", "Layout");
  }
}
