



























































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  NotificationModule,
  MessagingModule,
  IncidentModule,
  AuthModule,
  JWTModule,
  CommonStore,
  MetaModule,
} from "@/store/modules";
import { eventHub } from "@/utils/eventHub";
import Icon from "element-ui";
import { parseZone } from "moment";
import helper from "@/utils/helpers";
import { IncidentRouter } from "@/utils/routePathContsant";
import confirmDialog from "@/components/UIComponents/confirmDialog.vue";
import SocketHandler from "@/utils/socketHandler";
import { Role } from "@/utils/constants";
import { PanicMode } from "@/store/models/incident";
import incident from "../../store/api/incident";
import helpers from "../../utils/helpers";
@Component({
  components: { confirmDialog },
})
export default class Navbar extends Vue {
  centerDialogVisible = false;
  showDialog = false;
  dialogType = "";
  dialogMessage = "";
  selectedPanicModeId: any = "";

  alert: boolean = false;

  async created() {
    await MetaModule.loadRoutesOfUser();
    let token = helpers.getAccessToken();
    if (AuthModule.profile.Role !== Role.Admin && token !== null) {
      SocketHandler.createConnection();
      SocketHandler.createConnection(`chat/0/?token=${token}`);
    }
    //await IncidentModule.loadAllPanicList();
  }

  get userRoutes() {
    return MetaModule.routesListOfUser.map((x) => x.gid);
  }

  get isAdmin() {
    return AuthModule.profile.Role == Role.Admin;
  }
  get PanicDataLength() {
    return this.PanicList.length;
  }

  // showAlert() {
  //   if (this.PanicDataLength > 0) {
  //     alert();
  //     this.alert == true;
  //   }
  //   this.alert == false;
  // }

  get PanicList() {
    //Status: false
    var ls: PanicMode[] = [];
    for (var key in IncidentModule.panicModes) {
      if (IncidentModule.panicModes[key].Status) {
        ls.push(IncidentModule.panicModes[key]);
      }
    }
    return ls;
  }

  // get NewNotification() {
  //   const len = NotificationModule.notifications.length;
  //   if (len == 0) {
  //     return "";
  //   }
  //   return len;
  // }

  get NewNotification() {
    const len = CommonStore.liveMonitoringData.filter((x) =>
      this.userRoutes.includes(x.route)
    ).length;
    if (len == 0) {
      return "";
    }
    return len;
  }

  get Notifications() {
    return CommonStore.liveMonitoringData.filter((x) =>
      this.userRoutes.includes(x.route)
    );
  }
  get userName() {
    const obj: any = AuthModule.profile;
    if (!obj) {
      return "";
    }
    return obj.userDetail.username;
  }

  momentDate(date: any) {
    return helper.toMomentDate(date);
  }

  onLogoutItemClickConfirm(e: any) {
    this.$router.push({ name: "NewHome" });
    this.showDialog = false;
    SocketHandler.closeConnection();
    JWTModule.clearJWT();
  }

  onLogoutClicked(e: any) {
    this.dialogType = "logout";
    this.dialogMessage = "Are you sure you want to log out?";

    this.showDialog = true;
  }

  setStatus() {
    this.centerDialogVisible = true;
  }

  async onNotificationClicked(e: any) {
    // NotificationModule.setActiveNotification(e);
    // if (e.Type === "message") {
    //   MessagingModule.selectIncident(e.Id);
    //   eventHub.$emit("show-message-box", e);
    // }
    // const not = NotificationModule.notifications[e.Index];
    // if (not.Type == "incident") {
    //   let data = await this.loadIncidents(
    //     not.Id,
    //     not.Status,
    //     IncidentModule.incidentPageSize
    //   );
    // }
    // //
    // if (not.Type == "complain") {
    //   let data = await this.loadComplains(
    //     not.Id,
    //     not.Status,
    //     IncidentModule.incidentPageSize
    //   );
    // }
    // this.$router.push(not.RoutePath);
    CommonStore.setLiveVehicleId(e.deviceId);
    eventHub.$emit("live-list-clicked", e);
  }

  async loadIncidents(incidentId: string, status: string, pageSize: number) {
    let data = await IncidentModule.getIncidentsByPaging({
      SentIncidentId: incidentId,
      PageSize: pageSize,
      IncidentStatus: status,
    });
    eventHub.$emit("incidents-from-notification", data);

    return data;
  }

  async loadComplains(incidentId: string, status: string, pageSize: number) {
    let data = await IncidentModule.getComplainByPaging({
      SentIncidentId: incidentId,
      PageSize: pageSize,
      IncidentStatus: status,
    });
    eventHub.$emit("incidents-from-notification", data);

    return data;
  }

  onPanicItemClicked(e: any) {
    this.$router.push(
      IncidentRouter.IncidentDashboard,
      () => {
        // NotificationModule.setActiveNotification(e);
      },
      () => {
        //alert('not routings')
        /// NotificationModule.setActiveNotification(null);
        eventHub.$emit("panic-item-clicked", e);
      }
    );
  }

  onStopPanicModeClicked(e: any) {
    this.dialogType = "stopPanic";
    this.dialogMessage = "Are you sure you want to dismiss the panic mode ?";
    this.selectedPanicModeId = e;
    this.showDialog = true;
  }
  stopPanicMode(e: any) {
    IncidentModule.stopPanicMode(e);
  }
  peformAction(e: any) {
    if (this.dialogType == "stopPanic") {
      this.stopPanicMode(this.selectedPanicModeId);
    } else if (this.dialogType == "logout") {
      this.onLogoutItemClickConfirm(e);
    }
    this.showDialog = false;
  }
}
